import { configureStore } from "@reduxjs/toolkit";
import productsReducer, { productsFetch } from "../components/cart/productSlice";
import cartReducer, { getTotals } from "../components/cart/cartSlice";
import { productsApi } from "../components/cart/productsApi";

const store = configureStore({
    reducer: {
        products: productsReducer,
        cart: cartReducer,
        [productsApi.reducerPath]: productsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(productsApi.middleware),
}
);

store.dispatch(productsFetch());
store.dispatch(getTotals());

export default store;
