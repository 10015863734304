import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import MainLayout from "./components/layout/MainLayout";
// import PrivateRoute from './routes/PrivateRoute';
// import { Partytown } from '@builder.io/partytown/react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/css/App.css';

const Home = React.lazy(() => import('./pages/Home'));
const Producto = React.lazy(() => import('./pages/Producto'));
const Financiamiento = React.lazy(() => import('./pages/Financiamiento'));
const Carrito = React.lazy(() => import('./pages/Carrito'));
const Checkout = React.lazy(() => import('./pages/Checkout'));
const Authenticacion = React.lazy(() => import('./pages/Authenticacion'));
const SocioVendedor = React.lazy(() => import('./pages/Socio-vendedor'));
const NotFoundPage = React.lazy(() => import('./pages/404'));
// const Account = React.lazy(() => import('./pages/Cuenta'));
// const PrivatePage = React.lazy(() => import('./routes/PrivateRoute'));

function App() {
  AOS.init({ duration: 1500 });

  // <Partytown debug={true} forward={['dataLayer.push']} />
  /* <MarqueeBanner /> */
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path='/producto/:id' element={<Producto />} />
          <Route path="/financiamiento" element={<Financiamiento />} />
          <Route path="/carrito" element={<Carrito />} />
          <Route path="/confirmar-compra" element={<Checkout />} />
          <Route path="/authenticacion" element={<Authenticacion />} />
          {/* <Route path="/cuenta-usuario" element={<Account />} /> */}
          <Route path="/socio-vendedor" element={<SocioVendedor />} />
          <Route path="*" element={<NotFoundPage />} />
          {/* <PrivateRoute exact path="/private" component={PrivatePage} /> */}
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default React.memo(App);
