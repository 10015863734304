import { MDBSpinner } from 'mdb-react-ui-kit';

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <h1 className='text-center textgradient mt-5 py-5'>
                Error 500 de servidor...
                <MDBSpinner className='mx-2' color='info'></MDBSpinner>
            </h1>
            <b className='centered text-center textgradient py-3'>error ID:</b>
            <b className='centeredpy-3' >{error.message}</b>
            <button className="btn centered py-3" onClick={resetErrorBoundary}>Recargar la pagina</button>
        </div>
    )
}
export default ErrorFallback;