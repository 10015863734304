import React from 'react';

const Header = React.lazy(() => import('./Header'));
const Contacto = React.lazy(() => import('../../components/contacto/index'));
const GoogleMaps = React.lazy(() => import('../../components/about/googleMaps'));
const ScrollButton = React.lazy(() => import('../../components/backToTop/index'));
const MessengerButton = React.lazy(() => import('../../components/MessengerIcon/index'));

const Footer = React.lazy(() => import('./Footer'));

const MainLayout = ({ children }) => {
    return (
        <>
            <Header />
            {children}
            <Contacto />
            <GoogleMaps />
            <ScrollButton />
            <MessengerButton />
            <Footer />
        </>
    );
};

export default MainLayout;